import React, { ReactElement, useState } from 'react'
import LinkElement from './LinkElement'
import { Link, graphql, useStaticQuery } from 'gatsby'

const Navbar = (): ReactElement => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { datoCmsAsset } = useStaticQuery(resumeQuery)
  const links = [
    {
      url: 'https://www.github.com/raaims',
      text: 'Github',
    },
    {
      url: datoCmsAsset.url,
      text: 'Resume',
    },
  ]

  const toggleMenu = () => setMenuOpen(!isMenuOpen)
  return (
    <nav className='flex items-center justify-between flex-wrap bg-white p-6 sticky'>
      <div>
        <Link to='/'>
          <span role='img' aria-label='Hello!' className='text-4xl'>
            👋🏽
          </span>
        </Link>
      </div>
      <div className='block lg:hidden'>
        <button
          onClick={toggleMenu}
          className='flex items-center px-3 py-2 border rounded text-red-400 border-red-400 hover:text-white hover:border-white'
        >
          <svg
            className='fill-current h-3 w-3'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>
      </div>
      <div
        className={`w-full ${
          isMenuOpen ? 'block' : 'hidden'
        } lg:block lg:flex lg:items-center lg:w-auto`}
      >
        <div className='lg:flex-grow'>
          {links.map(({ url, text }) => {
            return <LinkElement key={text} url={url} text={text} />
          })}
        </div>
      </div>
    </nav>
  )
}

const resumeQuery = graphql`
  query ResumeQuery {
    datoCmsAsset(filename: { eq: "resume-raims-cabrera.pdf" }) {
      url
    }
  }
`

export default Navbar
