import React, { ReactElement } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Navbar, Footer } from './'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

interface Props {
  children: ReactElement
  pageTitle: string
  pageDescription: string
}

const Layout = ({
  children,
  pageTitle = '',
  pageDescription = '',
}: Props): ReactElement => {
  const { datoCmsSite } = useStaticQuery(layoutQuery)
  const { globalSeo } = datoCmsSite
  const { fallbackSeo, siteName, titleSuffix, twitterAccount } = globalSeo

  const title = isEmpty(pageTitle)
    ? `${siteName} - ${titleSuffix}`
    : `${pageTitle} - ${siteName}`

  const description = isEmpty(pageDescription)
    ? fallbackSeo.description
    : pageDescription

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='author' content='@raaims' />
        <meta property='og:title' content={title} />
        <meta property='og:image' content={fallbackSeo.image.url} />
        <meta property='og:description' content={description} />
        <meta property='twitter:card' />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:creator' content={twitterAccount} />
      </Helmet>
      <Navbar />
      <main className='container max-w-4xl p-10 mx-auto'>
        {children}
        <Footer />
      </main>
    </>
  )
}

const layoutQuery = graphql`
  query LayoutQuery {
    datoCmsSite {
      globalSeo {
        fallbackSeo {
          description
          title
          twitterCard
          image {
            url
          }
        }
        siteName
        titleSuffix
        twitterAccount
        facebookPageUrl
      }
    }
  }
`

export default Layout
