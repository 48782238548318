import React from 'react'

const Footer = () => {
  return (
    <footer className='relative bottom-0 px-1 py-8 lg:absolute lg:p-0 lg:pb-8'>
      <address className='not-italic'>
        You can email me at{' '}
        <a
          className='underline hover:text-red-400 transition ease-out duration-200'
          href='mailto:hello@raimscabrera.com'
        >
          hello@raimscabrera.com
        </a>
      </address>
    </footer>
  )
}

export default Footer
