import React, { ReactElement } from 'react'
import startsWith from 'lodash/startsWith'
import { Link } from 'gatsby'

interface Props {
  url: string
  text: string
}

const LinkElement = ({ url, text }: Props): ReactElement => {
  const classname =
    'block mt-4 lg:inline-block lg:mt-0 text-black hover:text-red-400 mr-4 transition ease-out duration-200'
  if (startsWith(url, 'https') || startsWith(url, 'www')) {
    return (
      <a
        href={url}
        className={classname}
        target='_blank'
        rel='noopener noreferrer'
      >
        {text}
      </a>
    )
  }

  return (
    <Link to={url} className={classname}>
      {text}
    </Link>
  )
}

export default LinkElement
